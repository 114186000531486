import styles from "./App.module.scss";
import Header from "./assets/components/Header/Header";
import { Outlet, useNavigate } from "react-router-dom";
import { Suspense, createContext, useEffect, useState } from "react";
import Loader from "./assets/components/Loader/Loader";
import DarkModeToggle from "./assets/components/DarkModeToggle/DarkModeToggle";

export const ThemeContext = createContext("");

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [theme, setTheme] = useState("light");

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    const timeout = setTimeout(() => {
      setIsLoading(false);
      window.scrollTo(0, 0);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [navigate]);

  function toggle() {
    setTheme((curr) => (curr === "light" ? "dark" : "light"));
  }

  return (
    <ThemeContext.Provider value={theme}>
      <div className={`${styles.container}`} id={theme}>
        <DarkModeToggle onClick={toggle} theme={theme} />
        <Header />
        <Suspense>
          <Outlet context={theme} />
        </Suspense>
        {isLoading && <Loader id={theme} />}
      </div>
    </ThemeContext.Provider>
  );
}

export default App;
