import { useState } from "react";
import styles from "./DarkModeToggle.module.scss";
import { useSpring, animated } from "react-spring";
import sun from "../../images/sun.svg";

function DarkModeToggle({
  onClick,
  theme,
}: {
  onClick: () => void;
  theme: string;
}) {
  const [on, setOn] = useState(false);

  const animation = useSpring({
    transform: on ? "rotate(237.deg)" : "rotate(47.deg)",
    config: { duration: 500 },
  });

  function handleClick() {
    setOn(!on);
    const music = new Audio(sound);
    music.play();
    onClick();
  }
  const sound = require("../../Sounds/click.mp3");

  return (
    <div className={`${styles.dark} ${theme + "-toggle"} d-flex`}>
      {on ? (
        <animated.i
          className="fa-solid fa-moon"
          style={animation}
          onClick={handleClick}
        ></animated.i>
      ) : (
        <animated.img
          src={sun}
          style={animation}
          onClick={handleClick}
        ></animated.img>
      )}
    </div>
  );
}

export default DarkModeToggle;
