import styles from "./Header.module.scss";
import logo from "../../images/Logo.svg";
import { Link } from "react-router-dom";
import { useState } from "react";
import HeaderMobile from "../HeaderMobile/HeaderMobile";
import logoHover from "../../images/LogoCouleurHeader.svg";

function Header() {
  const [menuMobile, setMenuMobile] = useState(false);
  const [imageHover, setImageHover] = useState(logo);

  function handleMouseEnter() {
    setImageHover(logoHover);
  }

  function handleMouseLeave() {
    setImageHover(logo);
  }

  function handleCloseMenu() {
    setMenuMobile(false);
  }

  return (
    <>
      <div className={`${styles.headerMobile}`}>
        {menuMobile ? (
          <i
            className="fa-solid fa-bars"
            style={{ color: "white" }}
            onClick={() => setMenuMobile(!menuMobile)}
          ></i>
        ) : (
          <i
            className="fa-solid fa-bars"
            onClick={() => setMenuMobile(!menuMobile)}
          ></i>
        )}

        {menuMobile && <HeaderMobile handleCloseMenu={handleCloseMenu} />}
      </div>

      <header
        className={`${styles.headercontainer} d-flex flex-column align-items-center p-5`}
      >
        <Link to="/">
          <img
            src={imageHover}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            alt="logo renard"
          />
        </Link>
        <nav>
          <ul className={`${styles.header}`}>
            <Link to="/a-propos">
              <li>À propos</li>
            </Link>
            <Link to="/services">
              <li>Services</li>
            </Link>
            <Link to="competences">
              <li>Compétences</li>
            </Link>
            <Link to="/realisations">
              <li>Réalisations</li>
            </Link>
            <Link to="/contact">
              <li>Contact</li>
            </Link>
          </ul>
        </nav>
        <ul className={`${styles.mentions}`}>
          <Link to="mentions-legales">
            <li>Mentions légales</li>
          </Link>
          <Link to="politique-de-donnees-personnelles">
            <li>Politique de données personnelles</li>
          </Link>
          <p>Version 1.3.0</p>
        </ul>
      </header>
    </>
  );
}

export default Header;
