import styles from "./Loader.module.scss";
import tomarik from "../../images/LogoCouleur.svg";
import tomarikWhite from "../../images/Logo.svg";

function Loader({ id }: { id: string }) {
  return (
    <div
      className={`${styles.Loader} dark d-flex flex-column fade-in justify-content-center align-items-center`}
      id={id}
    >
      {id === "dark" ? (
        <img src={tomarikWhite} alt="Renard Tomarik"></img>
      ) : (
        <img src={tomarik} alt="Renard Tomarik" />
      )}

      <p>Patience, patience...</p>
      <div className={`${styles.Loading}`}></div>
    </div>
  );
}

export default Loader;
