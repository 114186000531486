import styles from "./HeaderMobile.module.scss";
import { Link } from "react-router-dom";
import logo from "../../images/Logo.svg";
import logoCouleur from "../../images/LogoCouleurHeader.svg";
import { useEffect, useState } from "react";

function HeaderMobile({ handleCloseMenu }: { handleCloseMenu: () => void }) {
  const [isOpen, setIsOpen] = useState(true);
  const [logoHover, setLogoHover] = useState(false);

  useEffect(() => {
    if (isOpen && !logoHover) {
      setTimeout(() => {
        setLogoHover(true);
      }, 1000);
    }
  }, [isOpen, logoHover]);

  function handleLinkClick() {
    setIsOpen(false);
    handleCloseMenu();
  }

  return (
    isOpen && (
      <header
        className={`${styles.headercontainer} d-flex flex-column align-items-center p-5`}
      >
        <Link to="/" onClick={() => handleLinkClick()}>
          {!logoHover ? (
            <img className={`${styles.logo}`} src={logo} alt="logo renard" />
          ) : (
            <img
              className={`${styles.logo}`}
              src={logoCouleur}
              alt="logo renard"
            />
          )}
        </Link>
        <nav>
          <ul className={`${styles.header}`}>
            <Link to="a-propos" onClick={() => handleLinkClick()}>
              <li>À propos</li>
            </Link>
            <Link to="services" onClick={() => handleLinkClick()}>
              <li>Services</li>
            </Link>
            <Link to="competences" onClick={() => handleLinkClick()}>
              <li>Compétences</li>
            </Link>
            <Link to="realisations" onClick={() => handleLinkClick()}>
              <li>Réalisations</li>
            </Link>
            <Link to="contact" onClick={() => handleLinkClick()}>
              <li>Contact</li>
            </Link>
          </ul>
        </nav>
        <ul className={`${styles.mentions}`}>
          <Link to="mentions-legales" onClick={() => handleLinkClick()}>
            <li>Mentions légales</li>
          </Link>
          <Link
            to="politique-de-donnees-personnelles"
            onClick={() => handleLinkClick()}
          >
            <li>Politique de données personnelles</li>
          </Link>
        </ul>
        <p>Version 1.3.0</p>
      </header>
    )
  );
}

export default HeaderMobile;
