import { useRouteError } from "react-router-dom";
import styles from "./ErrorPage.module.scss";

function ErrorPage() {
  const error: any = useRouteError();
  return (
    <div className={`${styles.error} d-flex align-items-center justify-center`}>
      <p>Erreur</p>
      <p>{error.statusText}</p>
    </div>
  );
}

export default ErrorPage;
