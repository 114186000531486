import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import { lazy } from "react";

const HomePage = lazy(() => import("./pages/HomePage"));
const MentionsLegales = lazy(
  () => import("./pages/MentionsLegales/MentionsLegales")
);
const RGPD = lazy(() => import("./pages/RGPD/RGPD"));
const Contact = lazy(() => import("./pages/Contact/Contact"));
const About = lazy(() => import("./pages/About/About"));
const Realisations = lazy(() => import("./pages/Realisations/Realisations"));
const Competences = lazy(() => import("./pages/Competences/Competences"));
const JeLoueJeGagne = lazy(
  () =>
    import(
      "./pages/Realisations/pages/RealisationsJeLoueJeGagne/RealisationsJeLoueJeGagne"
    )
);
const LeclercJouetRefonte = lazy(
  () =>
    import(
      "./pages/Realisations/pages/RealisationsLeclercCarvinRefonte/RealisationsLeclercCarvinRefonte"
    )
);
const LeclercJouet = lazy(
  () =>
    import(
      "./pages/Realisations/pages/RealisationsLeclercJouetCarvinOld/RealisationsLeclercJouetCarvinOld"
    )
);

const LaTuileDeJeu = lazy(
  () =>
    import(
      "./pages/Realisations/pages/RealisationsLaTuileDeJeu/RealisationsLaTuileDeJeu"
    )
);

const NosServices = lazy(() => import("./pages/NosServices/NosServices"));

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <HomePage /> },
      {
        path: "realisations",
        element: <Realisations />,
      },
      { path: "realisations/je-loue-je-gagne", element: <JeLoueJeGagne /> },
      {
        path: "realisations/leclerc-jouet-refonte",
        element: <LeclercJouetRefonte />,
      },
      { path: "realisations/leclerc-jouet", element: <LeclercJouet /> },
      { path: "realisations/latuiledejeu", element: <LaTuileDeJeu /> },

      { path: "mentions-legales", element: <MentionsLegales /> },
      { path: "a-propos", element: <About /> },
      { path: "competences", element: <Competences /> },
      { path: "Contact", element: <Contact /> },
      { path: "Services", element: <NosServices /> },

      {
        path: "politique-de-donnees-personnelles",
        element: <RGPD />,
      },
    ],
  },
]);
